import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import axios from 'axios';
import jsPlumb from 'jsplumb' //连线插件
import util from './utils/index'; //公用方法
import './assets/style/reset.css'; //样式重置
import './assets/style/basic.scss' //基础公用样式
import './assets/font/font.css' //字体库
import 'animate.css'; //动画
import 'babel-polyfill'
import Mint from 'mint-ui';
import 'mint-ui/lib/style.css'
Vue.prototype.$axios = axios;
Vue.prototype.$util = util;
Vue.prototype.$jsPlumb = jsPlumb.jsPlumb
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Mint);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
