import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/login', 
    name: 'login',
    component: () => import('../views/login.vue') //登录
  },
  // {
  //   path: '/entry', 
  //   name: 'entry',
  //   component: () => import('../views/entry.vue') //百城计划登录
  // },
  {
    path: '/evaluation', 
    name: 'evaluation',
    component: () => import('../views/cityPlan/evaluation.vue') //pc端测评
  },
  {
    path: '/mEvaluation', 
    name: 'mEvaluation',
    component: () => import('../views/cityPlan/mEvaluation.vue') //移动端测评
  },
  {
    path: '/finish', 
    name: 'finish',
    component: () => import('../views/cityPlan/finish.vue') //pc端测评完成
  },
  {
    path: '/mFinish', 
    name: 'mFinish',
    component: () => import('../views/cityPlan/mFinish.vue') //移动端测评完成
  },
  {
    path: '/ques', 
    name: 'ques',
    component: () => import('../views/dispense/ques.vue') //题库测评
  },
  {
    path: '/mQues', 
    name: 'mQues',
    component: () => import('../views/dispense/mQues.vue') //题库测评
  },
  {
    path: '/quesFinish', 
    name: 'quesFinish',
    component: () => import('../views/dispense/finish.vue') //题库测评完成
  },
  {
    path: '/mQuesFinish', 
    name: 'mQuesFinish',
    component: () => import('../views/dispense/mFinish.vue') //移动端题库测评完成
  },
  {
    path: '/monitor', 
    name: 'monitor',
    component: () => import('../views/monitor.vue') //设备监测
  },
  {
    path: '/checkpoint', 
    name: 'checkpoint',
    component: () => import('../views/checkpoint.vue') //关卡
  },
  {
    path: '/getCard', 
    name: 'getCard',
    component: () => import('../views/common/getCard.vue') //完成获得卡牌
  },
  {
    path: '/topic', 
    name: 'topic',
    component: () => import('../views/common/topic.vue') //题目介绍
  },
  {
    path: '/practiceEnd', 
    name: 'practiceEnd',
    component: () => import('../views/common/practiceEnd.vue') //练习结束
  },
  {
    path: '/complete', 
    name: 'complete',
    component: () => import('../views/common/complete.vue') //恭喜完成
  },
  {
    path: '/relax', 
    name: 'relax',
    component: () => import('../views/common/relax.vue') //放松一下
  },
  {
    path: '/faceIdentify', 
    name: 'faceIdentify',
    component: () => import('../views/face/identify.vue') //1-2 识别阶段
  },
  {
    path: '/faceDelay', 
    name: 'faceDelay',
    component: () => import('../views/face/delay.vue') //1-2 延迟任务，连接题
  },
  {
    path: '/faceMemoryTip', 
    name: 'faceMemoryTip',
    component: () => import('../views/face/memoryTip.vue') //1-3 按键提示
  },
  {
    path: '/faceMemory', 
    name: 'faceMemory',
    component: () => import('../views/face/memory.vue') //1-3 记忆阶段
  },
  {
    path: '/continuousTip', 
    name: 'continuousTip',
    component: () => import('../views/continuous/tip.vue') //2-1 连续 按键提示
  },
  {
    path: '/continuous', 
    name: 'continuous',
    component: () => import('../views/continuous/index.vue') //2-1 连续 不要按X
  },
  {
    path: '/card', 
    name: 'card',
    component: () => import('../views/card/index.vue') //3-1 卡片实验
  },
  {
    path: '/delayTip', 
    name: 'delayTip',
    component: () => import('../views/delay/tip.vue') //4-1 延迟配对提示
  },
  {
    path: '/delay', 
    name: 'delay',
    component: () => import('../views/delay/index.vue') //4-1 延迟配对
  },
  {
    path: '/selfJudgeTip', 
    name: 'selfJudgeTip',
    component: () => import('../views/self/judgeTip.vue') //5-1 按键提示
  },
  {
    path: '/selfJudge', 
    name: 'selfJudge',
    component: () => import('../views/self/judge.vue') //5-1 判断阶段
  },
  {
    path: '/selfInterferTip', 
    name: 'selfInterferTip',
    component: () => import('../views/self/interferTip.vue') //5-2 按键提示
  },
  {
    path: '/selfInterfer', 
    name: 'selfInterfer',
    component: () => import('../views/self/interfer.vue') //5-2 干扰阶段
  },
  {
    path: '/selfMemory', 
    name: 'selfMemory',
    component: () => import('../views/self/memory.vue') //5-3 回忆阶段
  }
]

const router = new VueRouter({
  routes
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  // 去的不是登录页
  if (to.path != '/login') {
      // 没有登录
      if (sessionStorage.getItem('fmUserId')) {
        next();
      } else {
        next({
          path: '/login',
        });
      }
  } else {
    next();
  }
});
export default router
  