<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    //阻止页面回退
    window.onpopstate = () => {
      history.go(1);
    };
  },
};
</script>
<style>
#app {
  width: 100%;
  height: 100%;
  display: table;
}
</style>
